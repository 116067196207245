import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Recipient } from '.';

import api from '../../../../services/api';
import { useAlert, useDebounce } from '../../../../hooks';

const getOptionLabel = (recipient) => {
  if (
    recipient?.PessoasVinculadas &&
    recipient?.PessoasVinculadas.filter((p) => p.Selecionado).length > 0
  ) {
    return `${recipient.Nome} (${
      recipient.PessoasVinculadas.filter((p) => p.Selecionado).length
    })`;
  }
  return recipient?.Nome;
};

export function RecipientSelector({
  selectedRecipients,
  handleChangeSelectedRecipients,
  selectedTargets,
  setTotalTargets,
}) {
  const theme = useTheme();
  const debounce = useDebounce();
  const setAlert = useAlert();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [recipientNameSearch, setRecipientNameSearch] = useState('');
  const [pageRecipient, setPageRecipient] = useState(1);
  const [hasMoreRecipients, setHasMoreRecipients] = useState(false);

  const [recipients, setRecipients] = useState([]);
  const [loadingRecipient, setLoadingRecipient] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleSelectRecipient = (recip, relatedRecipient = null) => {
    setRecipients((prevRecipients) => {
      const updatedRecipients = prevRecipients.map((r) => {
        if (r.CodigoPessoa === recip.CodigoPessoa) {
          if (relatedRecipient !== null) {
            return {
              ...r,
              Selecionado: true,
              PessoasVinculadas: r?.PessoasVinculadas?.map((relatedR) => {
                if (relatedR.CodigoPessoa === relatedRecipient.CodigoPessoa) {
                  return { ...relatedR, Selecionado: !relatedR.Selecionado };
                }
                return relatedR;
              }),
            };
          }
          return {
            ...r,
            Selecionado: !r.Selecionado,
            PessoasVinculadas: r?.PessoasVinculadas?.map((relatedR) => {
              if (r.Selecionado) return { ...relatedR, Selecionado: false };
              return relatedR;
            }),
          };
        }
        return r;
      });
      if (recip.Selecionado && relatedRecipient == null) {
        handleChangeSelectedRecipients(
          selectedRecipients.filter(
            (r) => r.CodigoPessoa !== recip.CodigoPessoa
          )
        );
      } else {
        const newRecip = updatedRecipients.find(
          (r) => r.CodigoPessoa === recip.CodigoPessoa
        );
        handleChangeSelectedRecipients([
          ...selectedRecipients.filter(
            (r) => r.CodigoPessoa !== recip.CodigoPessoa
          ),
          newRecip,
        ]);
      }

      return updatedRecipients;
    });
  };

  const handleRequestRecipients = useCallback(
    (targets, searchQuery, loadMore = false) => {
      if (targets && targets.length === 0) {
        setPageRecipient(1);
        handleChangeSelectedRecipients([]);
        setRecipients([]);
        return;
      }

      if (!loadMore) setLoadingRecipient(true);
      else setLoadingMore(true);

      const params = {
        idsTargetAudience: targets.map((x) => x.id),
        page: loadMore ? pageRecipient : 1,
        perPage: 20,
      };

      if (searchQuery && searchQuery.length > 0) {
        params.recipientName = searchQuery;
      }

      api
        .get('target-audience/getRecipientsByTargetAudience', {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            setTotalTargets(response.data[0].Total);
          }
          const dataTemp = response.data.map((recip) => {
            const actualSelection =
              selectedRecipients.find(
                (sRecip) => sRecip.CodigoPessoa === recip.CodigoPessoa
              )?.Selecionado ?? false;
            return { ...recip, Selecionado: actualSelection };
          });

          setRecipients(
            loadMore ? [...recipients, ...dataTemp] : [...dataTemp]
          );
          setHasMoreRecipients(dataTemp && dataTemp.length >= params.perPage);

          setPageRecipient(params.page + 1);
          setLoadingMore(false);
          setLoadingRecipient(false);
        })
        .catch(() => {
          setLoadingMore(false);
          setLoadingRecipient(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro inesperado ao buscar público alvo.',
          });
        });
    },
    [selectedRecipients, recipients]
  );

  const debouncedRequest = useCallback(
    debounce((sTargets, rNameSearch) =>
      handleRequestRecipients(sTargets, rNameSearch)
    ),
    []
  );

  useEffect(() => {
    debouncedRequest(selectedTargets, recipientNameSearch);
  }, [selectedTargets, recipientNameSearch]);

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          marginTop: '15px',
        }}
        direction={isMobile ? 'column' : 'row'}
      >
        <Grid
          item
          md={2}
          style={{
            paddingTop: '8px',
          }}
        >
          <Typography component="span" color="primary">
            <strong>Destinatários</strong>
          </Typography>
        </Grid>
        <Grid item md={10}>
          <Autocomplete
            size="small"
            multiple
            freeSolo
            options={[]}
            value={selectedRecipients}
            disabled={selectedTargets.length === 0}
            inputValue={recipientNameSearch}
            onInputChange={(e) => {
              if (e?.target?.value !== undefined)
                setRecipientNameSearch(e?.target?.value);
            }}
            onChange={(_event, value) => {
              if (value !== undefined) handleChangeSelectedRecipients(value);
            }}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Procurar" />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item md={2} />
        <Grid item md={10}>
          <Recipient.List
            recipients={recipients}
            handleSelectRecipient={handleSelectRecipient}
            hasMoreRecipients={hasMoreRecipients}
            loadingMore={loadingMore}
            loadingRecipient={loadingRecipient}
            handleRequestRecipients={handleRequestRecipients}
            selectedTargets={selectedTargets}
            recipientNameSearch={recipientNameSearch}
          />
        </Grid>
      </Grid>
    </>
  );
}
