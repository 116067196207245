import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PrivateRoute from './PrivateRoute';
import { Core, Feature } from '../pages';
import Managers from '../managers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  '@global': {
    '::-webkit-scrollbar': {
      width: '0.6rem',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '10px',
      background: theme.palette.grey[100],
      margin: '1px',
      boxShadow: 'inset 0 0 2px grey',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: theme.palette.grey[100],
      boxShadow: 'inset 0 0 2px black',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.grey[300],
    },
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <Router>
      <Managers />
      <div className={classes.root}>
        <Switch>
          <PrivateRoute
            exact
            idFeature="home"
            path="/"
            component={Feature.Home}
          />
          <Route 
            exact 
            path="/newReleases" 
            component={Feature.NewReleases} 
          />
          <Route 
            exact 
            path="/configurations" 
            component={Feature.Configurations} 
          />
          <PrivateRoute
            exact
            idFeature="users"
            path="/users/"
            component={Feature.Users}
          />
          <PrivateRoute
            exact
            idFeature="users"
            path="/users/register"
            component={Feature.RegisterUser}
          />
          <PrivateRoute
            exact
            idFeature="users"
            path="/users/:userId"
            component={Feature.EditUser}
          />
          <PrivateRoute
            exact
            idFeature="applications"
            path="/applications/"
            component={Feature.Applications}
          />
          <PrivateRoute
            exact
            idFeature="applications"
            path="/applications/register"
            component={Feature.RegisterApplication}
          />
          <PrivateRoute
            exact
            idFeature="applications"
            path="/applications/:applicationId"
            component={Feature.EditApplication}
          />
          <PrivateRoute
            exact
            idFeature="sectors"
            path="/sector/"
            component={Feature.Sector}
          />
          <PrivateRoute
            exact
            idFeature="sectors"
            path="/sector/create"
            component={Feature.CreateSector}
          />
          <PrivateRoute
            exact
            idFeature="sectors"
            path="/sector/editSector/:idSector"
            component={Feature.EditSector}
          />
          <PrivateRoute
            exact
            idFeature="message-sector"
            path="/messageSector"
            component={Feature.MessageSector}
          />
          <PrivateRoute
            exact
            idFeature="message-sector"
            path="/message/chatSector/:idChat"
            component={Feature.ChatSector}
          />
          <PrivateRoute
            exact
            idFeature="message-sector"
            path="/eventReport/:idEvento"
            component={Feature.EventReport}
          />
          <PrivateRoute
            exact
            idFeature="message-sector"
            path="/batchReport/:idAssuntoMsgLote"
            component={Feature.BatchReport}
          />
          <PrivateRoute
            exact
            idFeature="message-sector"
            path="/message/chatAutInBatch/:idEvent"
            component={Feature.ChatAutorizationInBatch}
          />
          <PrivateRoute
            exact
            idFeature="message-sector"
            path="/message/chatInBatch/:idAssuntoMsgLote"
            component={Feature.ChatInBatch}
          />
          <PrivateRoute
            exact
            idFeature="target-audience"
            path="/target-audience/"
            component={Feature.TargetAudience}
          />
          <PrivateRoute
            exact
            idFeature="target-audience"
            path="/target-audience/register"
            component={Feature.RegisterTargetAudience}
          />
          <PrivateRoute
            exact
            idFeature="target-audience"
            path="/target-audience/:targetAudienceId"
            component={Feature.EditTargetAudience}
          />
          <PrivateRoute
            exact
            idFeature="target-audience"
            path="/target-audience/clone/:targetAudienceId"
            component={Feature.CloneTargetAudience}
          />
          <PrivateRoute
            exact
            idFeature="news"
            path="/news"
            component={Feature.Feed}
          />
          <PrivateRoute
            exact
            idFeature="news"
            path="/news/register"
            component={Feature.CreateNews}
          />
          <PrivateRoute
            exact
            idFeature="news"
            path="/news/:idNoticia"
            component={Feature.EditNews}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/gates"
            component={Feature.ManageGates}
          />
          <PrivateRoute
            exact
            idFeature="report-hello-i-arrived"
            path="/report-hello-i-arrived"
            component={Feature.ReportHelloArrived}
          />
          <PrivateRoute
            exact
            idFeature="control-panel-hello-i-arrived"
            path="/control-panel-hello-i-arrived"
            component={Feature.ControlPanelHelloArrived}
          />
          <PrivateRoute
            exact
            idFeature="control-panel-hello-i-arrived"
            path="/qrscanner"
            component={Feature.QrScanner}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/gates/createGate"
            component={Feature.CreateGate}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/gates/editGate:id"
            component={Feature.EditGate}
          />
          <PrivateRoute
            exact
            idFeature="early-exit"
            path="/early-exit/"
            component={Feature.EarlyExit}
          />
          <PrivateRoute
            exact
            idFeature="early-exit"
            path="/early-exit/create"
            component={Feature.CreateExit}
          />
          <PrivateRoute
            exact
            idFeature="early-exit"
            path="/early-exit/edit"
            component={Feature.EditExit}
          />
          <PrivateRoute
            exact
            idFeature="menu"
            path="/menu/"
            component={Feature.Menu}
          />
          <PrivateRoute
            exact
            idFeature="menu"
            path="/menu/register"
            component={Feature.RegisterMenu}
          />
          <PrivateRoute
            exact
            idFeature="menu"
            path="/menu/:menuId"
            component={Feature.EditMenu}
          />
          <PrivateRoute
            exact
            idFeature="menu"
            path="/menu/clone/:menuId"
            component={Feature.CloneMenu}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-itemsDR"
            component={Feature.ManageItemsDR}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-itemsDR/register-itemsDR"
            component={Feature.RegisterItemsDR}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-itemsDR/clone/:id"
            component={Feature.CloneItemRD}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-itemsDR/edit/:id"
            component={Feature.EditItemRD}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-settingsDR"
            component={Feature.ManageSettingsDR}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-settingsDR/register-settingDR"
            component={Feature.RegisterSettingDR}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-settingsDR/edit/:id"
            component={Feature.EditSetting}
          />
          <PrivateRoute
            exact
            idFeature="diary-record"
            path="/manage-settingsDR/clone/:id"
            component={Feature.CloneSetting}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-files/"
            component={Feature.ConfigurationFiles}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-files/create"
            component={Feature.CreateConfigurationFiles}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-files/clone/:configurationFileId"
            component={Feature.EditConfigurationFiles}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-files/:configurationFileId"
            component={Feature.EditConfigurationFiles}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-messages/"
            component={Feature.ConfigurationMessages}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-messages/create"
            component={Feature.CreateConfigurationMessages}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-messages/clone/:configurationMessageId"
            component={Feature.EditConfigurationMessages}
          />
          <PrivateRoute
            exact
            idFeature="home"
            path="/configuration-messages/:configurationMessageId"
            component={Feature.EditConfigurationMessages}
          />  
          <PrivateRoute
            exact
            idFeature="links"
            path="/links"
            component={Feature.Links}
          />
          <PrivateRoute
            exact
            idFeature="links"
            path="/links/editLink/:id"
            component={Feature.EditLink}
          />
          <Route path="/login" component={Core.Login} />
          <Route path="/forgot" component={Core.ForgotPassword} />
          <Route path="/reset/:token" component={Core.ResetPassword} />
          <Route
            path="/definepassword/:token"
            component={() => <Core.ResetPassword isDefinePassword />}
          />
          <Route path="*" component={Core.NotFound} />
        </Switch>
      </div>
    </Router>
  );
};
