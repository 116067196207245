import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
  Toolbar,
  TableContainer,
  TablePagination,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  useMediaQuery,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import moment from 'moment';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import { RoundedInput, TableRowFocusable } from '../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  headerMain: {
    padding: '10px 15px 10px 25px',
  },
  buttons: {
    marginTop: '4em',
    textAlign: 'right',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  statusContent: {
    justifyContent: 'center',
    display: 'flex',
  },
  input: {
    margin: '1.5em auto 1em 0',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  cellContent: {
    justifyContent: 'center',
    padding: '0px',
    fontSize: '16px',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  cell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px',
  },
  divNameRecip: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  divNames: {
    display: 'flex',
    flexDirection: 'column',
  },
  sonName: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '11px',
  },
  divPublicosSelecionados: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0.5em',
  },
}));

export default ({
  handleClose = () => {},
  open = false,
  numberViewed,
  numberNotViewed,
  recipientNumber,
  idAssuntoMsgLote,
  publicosSelecionados = '',
}) => {
  const classes = useStyles();
  const setAlert = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [text, setText] = useState('');
  const [filter, setFilter] = useState({ idAssuntoMsgLote });
  const [isLoading, setIsLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const handleSendEnterPress = (text, e) => {
    if (e.key === 'Enter') handleSearch(text);
  };

  const handleSearch = (message) => {
    if (message === text) return;

    setPage(1);
    setText(message);
    setFilter({
      name: message,
      idAssuntoMsgLote,
    });
  };

  useEffect(() => {
    if (open) {
      requestRecipients(page);
    }
  }, [open, filter]);

  const requestRecipients = async (pag) => {
    setIsLoading(true);

    const params = {
      page: pag,
      limit: 5,
      ...filter,
    };
    api
      .get('chat/getRecipientsBatch', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso)
          handleErrorRecipients(response.data.Mensagem);
        else {
          setTotal(response.data.Dados.Total);
          setPage(response.data.Dados.Page);
          setRecipients([...response.data.Dados.Data]);
          setIsLoading(false);
        }
      })
      .catch(() => handleErrorRecipients(null));
  };

  const handleErrorRecipients = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um problema inesperado!',
    });

    setRecipients([]);
    setPage(1);
    setTotal(0);
  };

  const handleChangePage = (event, nextPage) => requestRecipients(nextPage + 1);

  const handleFilterStatus = (idStatus) => {
    setPage(1);
    setFilter({
      status: idStatus,
      idAssuntoMsgLote,
    });
  };

  const handleCloseModal = () => {
    setFilter({ idAssuntoMsgLote });
    setText('');
    handleClose();
  };

  return (
    <Dialog
      maxWidth={false}
      onClose={() => handleCloseModal()}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className={classes.container}>
        <DialogActions className={classes.headerMain}>
          <Typography
            component="span"
            color="primary"
            style={{ marginRight: 'auto' }}
          >
            <strong>DETALHAMENTO DOS ENVIOS</strong>
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleCloseModal()}
          >
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: '5px 25px 25px 25px' }}>
          <div className={classes.status}>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleFilterStatus(1)}
            >
              <span style={{ color: '#4EB275' }}>Destinatários: </span>
              <div className={(classes.cellContent, classes.statusContent)}>
                {recipientNumber}
              </div>
            </div>
            <div
              style={{ justifyContent: 'center', cursor: 'pointer' }}
              onClick={() => handleFilterStatus(2)}
            >
              <span style={{ color: '#FB8C00' }}>Visualizados:</span>
              <div className={(classes.cellContent, classes.statusContent)}>
                {numberViewed}
              </div>
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => handleFilterStatus(3)}
            >
              <span style={{ color: '#FF5252' }}>Não visualizados: </span>
              <div className={(classes.cellContent, classes.statusContent)}>
                {numberNotViewed}
              </div>
            </div>
          </div>
          <div
            style={publicosSelecionados === '' ? { display: 'none' } : {}}
            className={classes.divPublicosSelecionados}
          >
            <strong>Público-alvo selecionado:&nbsp;</strong>
            <p style={{ margin: '0px' }}>{publicosSelecionados}</p>
          </div>
          <Toolbar style={{ padding: '0px' }}>
            <RoundedInput
              variant="outlined"
              placeholder="Pesquisar por destinatário"
              size="small"
              fullWidth
              className={classes.input}
              inputProps={
                isMobile
                  ? {
                      style: { fontSize: '11px' },
                    }
                  : {
                      style: { fontSize: '16px' },
                    }
              }
              onBlur={(e) => handleSearch(e.target.value)}
              onKeyPress={(e) => handleSendEnterPress(e.target.value, e)}
            />
          </Toolbar>
          <TableContainer>
            <Table
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TableBody style={{ display: 'flex', flexDirection: 'column' }}>
                {isLoading
                  ? new Array(10)
                      .fill()
                      .map((e, i) => i)
                      .map((e) => (
                        <TableRowFocusable key={e} onClick={() => {}} hover>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRowFocusable>
                      ))
                  : recipients?.map((recip) => (
                      <TableRow
                        key={recip.IdMensagem}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <TableCell className={classes.cell}>
                          <div className={classes.divNameRecip}>
                            {isMobile ? null : (
                              <Avatar src={recip.Foto}>
                                {recip.Nome[0]?.toUpperCase()}
                              </Avatar>
                            )}
                            <div
                              className={classes.divNames}
                              style={{
                                marginLeft: isMobile ? 0 : '8px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                                className={classes.cellContent}
                              >
                                {recip.Nome}
                                <span className={classes.sonName}>
                                  {recip.Papeis}
                                </span>
                              </span>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          style={{ justifyContent: 'center' }}
                          className={classes.cell}
                        >
                          {recip.DataEnvio != null ? (
                            <div className={classes.cellContent}>
                              <span>Hora de envio:</span>
                              <br />
                              <span>
                                {moment(recip.DataEnvio).format(
                                  'DD/MM - HH:mm'
                                )}
                              </span>
                            </div>
                          ) : (
                            <span>-</span>
                          )}
                        </TableCell>
                        <TableCell
                          style={{ justifyContent: 'center' }}
                          className={classes.cell}
                        >
                          {recip.DataVisualizacao != null ? (
                            <div className={classes.cellContent}>
                              <span>Visualizado:</span>
                              <br />
                              <span>
                                {moment(recip.DataEnvio).format(
                                  'DD/MM - HH:mm'
                                )}
                              </span>
                            </div>
                          ) : (
                            <span className={classes.cellContent}>
                              Não visualizado
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={total}
            rowsPerPage={5}
            page={page - 1}
            onChangePage={handleChangePage}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
};
