import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { RoundedButton } from '../../../components';

const useStyles = makeStyles(() => ({
  content: {
    padding: '15px 25px 15px 25px',
  },
  divButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '15px',
  },
}));

export default ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const [sendDate, setSendDate] = useState(moment().format('YYYY-MM-DD'));
  const [sendHour, setSendHour] = useState(moment().format('HH:mm'));

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogContent className={classes.content}>
        <Typography color="primary" style={{ marginBottom: '15px' }}>
          <strong>Agendar envio</strong>
        </Typography>
        <div>
          <TextField
            label="Data"
            size="small"
            variant="outlined"
            type="date"
            style={{ marginRight: '8px' }}
            defaultValue={sendDate}
            onChange={(e) => setSendDate(e.target.value)}
          />
          <TextField
            label="Hora"
            size="small"
            variant="outlined"
            type="time"
            defaultValue={sendHour}
            onChange={(e) => setSendHour(e.target.value)}
          />
        </div>
        <div className={classes.divButtons}>
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            Cancelar
          </RoundedButton>
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            onClick={() => handleConfirm(sendDate, sendHour)}
          >
            Agendar
          </RoundedButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
