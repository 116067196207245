import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Modal,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import { Settings, NotificationsNone, SmsOutlined } from '@material-ui/icons';
import BuildIcon from '@material-ui/icons/Build';
import { Creators as NotificationActions } from '../../store/ducks/notification';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import api from '../../services/api';
import Feedback from '../../pages/Feedback';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '1%',
    top: '80%',
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '190px',
    height: '35px',
  },
  title: {
    margin: '0em 1em 0em 1em',
    [theme.breakpoints.up('sm')]: {
      margin: '0em 1em 0em 2em',
    },
    fontSize: '1.5em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  btnNewReleases:{
    width: '24px', 
    height: '24px', 
    borderRadius: '50%', 
    backgroundColor: '#00796b', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  buildIcon: {
    fontSize: '14px', 
    color: '#fff'
  },

}));

export default ({ title, children, handleDrawerToggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.notification);
  const isAppOwner = useSelector((state) => state.profile.isAppOwner);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenNotif = (event) => {
    api.put('notification/registerNotifications');

    dispatch(
      NotificationActions.setNotification({
        unread: 0,
        notifications: [...notif.notifications],
      })
    );

    dispatch(
      NotificationActions.setAnchor({
        anchorEl: event.currentTarget,
      })
    );
  };

  const handleOpenFeedback = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
      >
        <Feedback setModalOpen={setModalOpen} />
      </Modal>
      <AppBar className={classes.root} position="sticky">
        <Toolbar>
          <Hidden xsDown implementation="css">
            <Link to="/">
              <Logo className={classes.logo} />
            </Link>
          </Hidden>
          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.grow} />
          {children}
          <Link
            to="/newReleases"
          >
            <IconButton
              color="primary"
              component="span"
            >
              <StyledBadge color="secondary" variant="dot">
                <div className={classes.btnNewReleases}>
                  <BuildIcon className={classes.buildIcon} />
                </div>
              </StyledBadge>
            </IconButton>
          </Link>
          <IconButton
            color="primary"
            component="span"
            onClick={handleOpenFeedback}
          >
            <SmsOutlined />
          </IconButton>

          {isAppOwner && (
            <Link
              to="/configurations"
            > 
              <IconButton
                color="primary"
                component="span"
              >
                <Settings />
              </IconButton>
            </Link>
          )}
          
          <IconButton
            color="primary"
            component="span"
            style={{ marginRight: '2em' }}
            onClick={handleOpenNotif}
          >
            <Badge
              color="secondary"
              variant="dot"
              invisible={notif?.unread === 0}
            >
              <NotificationsNone />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};
